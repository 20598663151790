<template>
    <v-container fluid dense>
        <v-data-table dense :loading="loading" :headers="headers" :items="items">
            <template v-slot:top>
                <v-row>
                    <v-col class="h3--text text-center font-weight-bold" :loading="loading" read-only>
                         {{ resultado }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.percent_total`]="{item}">
                <SaldoPro 
                    :url="'uteis/filtro-condicional/?table=producao-orcamentos'" 
                    :urlv="''" 
                    :lista="items" 
                    :objeto-current="item"
                />
            </template>   
        </v-data-table>
    </v-container>
</template>
<script>
import api from '../../../http';

export default{
    name:'ProcedExpand',
    components:{
        SaldoPro:()=>import('./Saldo.vue')
    },
    props: {view:{type:String},url:{type:String}},
    data:()=>({headers:[], items:[], resultado:'RESUMO POR PROFISSIONAL', loading:true}),
    methods:{
        async get_listas(url){
            const res = await api.get(`${url}`)
            try{
                this.items = res.data.map((e,i)=>{ 
                    e['id']=`pprof${i}` 
                    return e
                })
                this.items = res.data //
                this.headers = 
                [
                    {text:'Procedimento', value:'procedimento'},
                    {text:'Profissional', value:'apelido'},
                    {text:'Qtd. procedimentos evoluidos', value:'qtd_evoluidas'},
                    {text:'Qtd. procedimentos validadas (Evolução + Assinatura)', value:'qtd_assinadas'},
                    {text:'Qtd. procedimentos não validados', value:'evo_npg'},
                    {text:'% de cobertura', value:'percent_total'},
                ]
            }
            catch{e=>{console.log(e)}}
            finally{ this.loading = false}
        }
    },
    mounted(){
        this.get_listas(this.url)
    }
}
</script>